.text_40{
    font-size: 40px;
    margin: 0 0 3% 0;
}
.title{
    font-size: 30px;
    margin: 0 0 2% 0;
}
.last_update{
    margin: 0 0 1% 0;
}
.under_title{
    font-size: 25px;
    margin: 3% 0 0 0;
}
.subtitle{
    font-size: 20px;
    margin: 2% 0 0 0;
}
.text{
    margin: 0 0 1% 0;
}
.privacypolicy{
    margin: 0 0 0 5%;
}