@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

:root {
  --primary-color: #8e00a1;
  --secondary-color: #0887ff;
  --dark-color: rgb(0, 0, 0);
  --dark-blue-color: rgba(0, 37, 73, 1);
  --shadow-color: rgb(30, 30, 30);
  --white: #fff;
}
body {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.minwidth {
  min-width: 500px;
}
.text_20 {
  font-size: 20px;
}
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: #fff;
}
.navbar {
  background-color: var(--dark-color) !important;
}
.offcanvas-title {
  color: #fff;
}
.offcanvas-header,
.offcanvas-body {
  background-color:var(--dark-color);
  color: #707070;
}
.btn-close{
  background: transparent url(./images/close.svg) center/1em auto no-repeat !important;
}
.logo_text_full {
  font-family: -apple-system, BlinkMacSystemFont, "Josefin Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  background: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.25px #fff;
}
.logo_text_short {
  font-family: -apple-system, BlinkMacSystemFont, "Josefin Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  display: none;
  background: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.1px #fff;
}
.logo_text_full_footer{
  font-family: -apple-system, BlinkMacSystemFont, "Josefin Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  background: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.25px #fff;
  height: 15vh;
  font-size: 20px;
}
.landing_main {
  height: 93vh;
  background: linear-gradient(
    0deg,
    rgba(142, 0, 161, 1) 0%,
    rgba(68, 0, 77, 1) 10%,
    rgba(32, 0, 37, 1) 25%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 10, 21, 1) 75%,
    rgba(0, 22, 44, 1) 90%,
    rgba(0, 37, 73, 1) 100%
  );
}
.landing_text {
  margin: 10% 0 0 30%;
}
.landing_title,
.aboutus_title {
  font-size: 60px;
  font-weight: 700;
}
.landing_subtext,
.career_subtext {
  margin: 5% 0 0 0;
}
.landing_3d {
  height: 80vh;
}
/*#region loader*/
.loader {
  position: relative;
  margin: auto;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  -webkit-mask: linear-gradient(
    -45deg,
    var(--primary-color),
    var(--white),
    var(--secondary-color)
  );
  transform-origin: 50% 60%;
  transform: perspective(200px) rotateX(66deg);
  animation: loader-wiggle 1.2s infinite;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  margin: -4px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 0.05;
  border: inherit;
  border-color: transparent;
  animation: loader-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    loader-fade 1.2s linear infinite;
}
.loader:before {
  border-top-color: #66e6ff;
}
.loader:after {
  border-top-color: var(--primary-color);
  animation-delay: 0.3s;
}
@keyframes loader-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-fade {
  20% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.1;
  }
}
/*#endregion loader*/
.aboutus_title {
  margin:10% 0 0 0;
}
.aboutus_main {
  height: 90vh;
  background-color: #000;
}
.aboutus_technology_text {
  font-size: 40px;
  font-weight: 700;
  margin: 0 0 0 0;
}
.aboutus_graphic {
  width: 30vw;
  margin: 20% 0 0 0;
  min-width: 300px;
}
.logos {
  max-height: 5vh;
}
.aboutus_text {
  min-height: 30vh;
  margin: 0 0 5% 0;
  max-width: 35vw;
  margin-left: auto;
  margin-right: auto;
}
.aboutus_subtext {
  margin: 8% 0 0 0;
}
.aboutus_graphic{
  margin: 25% 0 0 0;
}
.wedo_main {
  height: 65vh;
  background: linear-gradient(
    0deg,
    rgba(142, 0, 161, 1) 0%,
    rgba(68, 0, 77, 1) 10%,
    rgba(32, 0, 37, 1) 25%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 10, 21, 1) 75%,
    rgba(0, 22, 44, 1) 90%,
    rgba(0, 37, 73, 1) 100%
  );
}
.wedo_graphic {
  width: 15vw;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.wedo_infotext {
  font-size: 30px;
  text-align: center;
}
.wedo_title {
  font-size: 30px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.wedo_text {
  max-width: 300px;
}
.wedo_ornament {
  background: var(--primary-color);
  background: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  width: 30vw;
  height: 20%;
  margin-left: auto;
  margin-right: auto;
}
.alertwrapper_main {
  height: 30vh;
  background: var(--dark-color);
}
.alertwrapper_title {
  font-size: 30px;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quote {
  height: 5vh;
  margin: 0 1% 0 1%;
}
.swiper {
  width: 20vw;
  margin: 10% 0 0 0;
}
.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 70%;
  object-fit: cover;
}
.mySwiperCards {
  width: 80vw;
}
.card_title {
  font-family: -apple-system, BlinkMacSystemFont, "Sevillana", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  width: 100vw;
  height: 5vh;
  font-size: 5vh;
}

.animation_line {
  width: 100%;
  height: 0.5vh;
  background: linear-gradient(
    -45deg,
    var(--primary-color) 30%,
    var(--white) 51%,
    var(--secondary-color) 70%
  );
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
}
.cardgame_main {
  height: 20vh;
  background-image: url(./images/Cards/magicbook-01.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.cardgame_text {
  font-size: 4.75vh;
  text-align: center;
  padding: 50px 0;
}
.cardgame_info {
  height: 40vh;
}
.cardgame_infotext {
  margin: 10% 0 0 30%;
}
.career_main {
  height: 93.2vh;
  background: rgb(33, 37, 41);
  background: linear-gradient(
    0deg,
    var(--dark-color) 0%,
    var(--dark-color) 74%,
    var(--dark-blue-color) 100%
  );
}
.career_graphic {
  margin: 0 5% 0 0;
  width: 100%;
}
.career_text {
  margin: 10% 0 0 30%;
}
.career_title {
  font-size: 50px;
  font-weight: 700;
}
.ornament {
  width: 50%;
  height: 4%;
  background: var(--primary-color);
  background: linear-gradient(
    90deg,
    var(--primary-color) 0%,
    var(--secondary-color) 100%
  );
  margin: 8% 0 2% 0;
}
.object {
  min-width: 350px;
}
.graphic {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.footer_main {
  background: rgb(33, 37, 41);
  background: linear-gradient(
    0deg,
    rgba(142, 0, 161, 1) 0%,
    rgba(68, 0, 77, 1) 10%,
    rgba(32, 0, 37, 1) 25%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 10, 21, 1) 75%,
    rgba(0, 22, 44, 1) 90%,
    rgba(0, 37, 73, 1) 100%
  );
}
.submit_button{
  background-color: var(--secondary-color) !important;
}
.address {
  margin: 20% 0 0 0;
}
.mail {
  margin: 0 0 20% 0;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/*media*/
@media only screen and (max-width: 1155px) {
  .career_title {
    font-size: 38px;
  }
  .career_main {
    height: 93vh;
  }
  .landing_title {
    font-size: 50px;
    font-weight: 700;
  }
}
@media only screen and (max-width: 1300px) {
  .landing_title{
    font-size: 50px;
  }
  .landing_subtext{
    font-size: 15px;
  }
}
@media only screen and (max-width: 1000px) {
  .landing_main {
    height: 136vh;
  }
  .landing_3d {
    height: 60vh;
  }
  .career_main {
    height: 140vh;
  }
  .aboutus_main {
    height: 140vh;
  }
  .aboutus_maintext{
    height: 50vh;
  }
  .aboutus_addtext{
    height: 50vh;
  }
  .animation-wrapper {
    height: 152vh;
  }
  .landing_text,
  .career_text {
    margin: 12% 0 0 8%;
  }
  .aboutus_title {
    font-size: 50px;
    font-weight: 700;
  }
  .landing_subtext,
  .career_subtext {
    margin: 5% 0 0 0;
    font-size: 15px;
  }
  .aboutus_subtext {
    font-size: 13px;
  }
  .career_title {
    font-size: 45px;
  }
  .aboutus_technology_text {
    font-size: 30px;
  }
}
@media only screen and (max-width: 770px) {
  .logo_text_short {
    display: inline-block;
    margin: 0 0 0 15%;
  }
  .logo_text_full {
    display: none;
  }
  .wedo_main{
    height: 110vh;
  }
  .alertwrapper_main{
    height: 50vh;
  }
}
@media only screen and (max-width: 700px) {
  .minwidth {
    min-width: 355px;
  }
  .landing_main {
    height: 125vh;
  }
.landing_title{
  font-size: 45px;
}
  .aboutus_main {
    height: 150vh;
  }
  .aboutus_text{
    max-width: 50vw;
  }
  .career_title {
    font-size: 40px;
  }
  .career_main {
    height: 120vh;
  }
}
@media only screen and (max-width: 500px) {
  .landing_main {
    height: 115vh;
  }
  .landing_3d {
    height: 45vh;
  }
  .alertwrapper_main{
    height: 63vh;
  }
}
@media only screen and (max-width: 450px) {
  .landing_main {
    height: 125vh;
  }
  .landing_3d {
    height: 45vh;
  }
  .aboutus_addtext {
    height: 20vh;
  }
  .aboutus_main {
    height: 120vh;
  }
  .aboutus_maintext {
    height: 10vh;
  }
  .aboutus_text {
    height: 5vh;
    max-width: 70vw;
  }
  .aboutus_technology_text{
    margin: 25% 0 0 0;
  }
  .aboutus_graphic {
    margin: 20% 0 0 0;
  }
}
